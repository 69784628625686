<template>
  <b-card-actions action-collapse title="Daftar Kas">
    <b-row>
      <b-col cols="6">
        <!-- <b-row> -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-tambah
            v-if="allowCreate() || allowUpdate()"
            variant="primary"
            @click="add()"
            >
            <!-- v-if="allowCreate()" -->
            <feather-icon icon="PlusIcon" class="mr-50" />
            Kas
          </b-button>
        </b-col>
        <b-col cols="6" align="right">
          <h3 align="right" class="text-info">Saldo : Rp {{ formatRupiah(getNominal(items)) }}</h3>
        <!-- </b-row> -->

        <b-modal
          v-if="allowCreate() || allowUpdate()"
          v-model="showModal"
          id="modal-"
          ok-title="Tutup"
          ok-variant="secondary"
          ok-only
          centered
          title="Form "
        >
          <validation-observer ref="formkas">
            <div class="d-flex">
              <feather-icon icon="ArchiveIcon" size="19" />
              <h6 class="ml-50 mb-2">Data Kas</h6>
            </div>
            <b-form>
              <b-row>
                <!-- Tanggal -->
                <b-col cols="12">
                  <b-form-group label="Tanggal" label-for="v-tanggal">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="tanggal"
                    >
                      <flat-pickr
                        v-model="form.tanggal"
                        class="form-control"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- nama -->
                <b-col cols="12">
                  <b-form-group label="Nama Kas" label-for="v-nama">
                    <validation-provider
                      #default="{ errors }"
                      rules="required|min:2"
                      name="kas"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form.nama"
                        id="v-nama"
                        placeholder="Isi Nama Kas"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                
                <!-- nama bank -->
                <!-- <b-col cols="12">
                  <b-form-group label="Nama Bank" label-for="v-nama_bank">
                    <validation-provider
                      #default="{ errors }"
                      rules="required|min:2"
                      name="nama_bank"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form.nama_bank"
                        id="v-nama_bank"
                        placeholder="Isi nama bank"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->

                <!-- nama Pemilik Rekening -->
                <!-- <b-col cols="12">
                  <b-form-group label="Nama Pemilik Rekening" label-for="v-pemilik_rekening">
                    <validation-provider
                      #default="{ errors }"
                      rules="required|min:2"
                      name="pemilik_rekening"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form.pemilik_rekening"
                        id="v-pemilik_rekening"
                        placeholder="Isi Nama Pemilik Rekening"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->

                <!-- Nomor Rekening -->
                <!-- <b-col cols="12">
                  <b-form-group label="Nomor Rekening" label-for="v-nomor_rekening">
                    <validation-provider
                      #default="{ errors }"
                      rules="required|min:2"
                      name="nomor_rekening"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form.nomor_rekening"
                        id="v-nomor_rekening"
                        placeholder="Isi Nomor Rekening"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                
                <!-- Member -->
                <!-- <b-col cols="12">
                  <b-form-group label="Member" label-for="v-member">
                    <validation-provider
                      #default="{ errors }"
                      rules="required|min:2"
                      name="member"
                    >
              <b-form-select :state="errors.length > 0 ? false : null" v-model="form.member_id" :options="member_id" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->

                <!-- rekening -->
                <b-col cols="12">
                  <b-form-group label="Rekening" label-for="v-rekening">
                    <validation-provider
                      #default="{ errors }"
                      rules="required|min:2"
                      name="rekening"
                    >
                      <!-- <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="form.rekening"
                        id="v-rekening"
                        placeholder="Isi rekening"
                      /> -->
              <b-form-select :state="errors.length > 0 ? false : null" v-model="form.rekening_id" :options="rekening_id" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- keterangan -->
                <b-col cols="12">
                  <b-form-group label="Keterangan" label-for="v-keterangan">
                    <validation-provider
                      #default="{ errors }"
                      rules="required|min:2"
                      name="keterangan"
                    >
                      <b-form-textarea
                        :state="errors.length > 0 ? false : null"
                        v-model="form.keterangan"
                        id="v-keterangan"
                        placeholder="Isi Keterangan"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- submit and reset -->
                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    @click.prevent="submit"
                    variant="primary"
                    class="mr-1"
                  >
                    Simpan
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                  >
                    Reset
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          :label="$t('Sort')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          :label="$t('Filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          small
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(no)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
          </template>
          <template #cell(saldo)="{ item }">
            <strong
              >
              {{
                item.saldo >= 0 ? "Rp " + formatRupiah(item.saldo) : "Rp "+ formatRupiah(item.saldo)
              }}</strong
            >
          </template>
          <!-- <template #cell(saldo)="{ item }">
            <strong
              >
              {{
                item.saldo >= 0 ? "Rp " + formatRupiah(item.saldo) : "Rp " + "0"
              }}</strong
            >
          </template> -->

          <!-- <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template> -->

          <template #cell(actions)="row">
            <b-button
              title="'Rincian'"
              size="sm"
              @click.prevent="$router.push(`/kas-alur/${row.item.id}`)"
              class="mr-1"
              variant="outline-success"
            >
              <feather-icon icon="ListIcon" />
            </b-button>
            <b-button
              title="'Ubah'"
              v-if="allowUpdate()"
              size="sm"
              @click="edit(row.item)"
              class="mr-1"
              variant="outline-info"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-if="allowDelete()"
              title="'Hapus'"
              size="sm"
              @click="remove(row.item)"
              class="mr-1"
              variant="outline-danger"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>
      <b-modal :id="detailmodal.id" :title="detailmodal.title" ok-only>
        <pre>{{ detailmodal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BTable,
  VBTooltip,
  BFormDatepicker,
  BFormTimepicker,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    VBTooltip,
    BFormTimepicker,
    BForm,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BFormDatepicker,
    BCardText,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      showModal: false,
      required,
      password,
      email,
      confirmed,
      form: {
        tanggal: new Date(),
        nama: "",
        pemilik_rekening: "",
        nama_pemilik: "",
        no_rek: "",
        member_id: "",
        keterangan: "",
        rekening_id: "",
      },
      member_id: [],
      rekening_id: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      perPage: 10,
      pageOptions: [5, 10, 25,50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      detailmodal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "no", label: "no" },
        // { key: "id", label: "Id"},
        // { key: "tanggal", label: "Tgl", sortable: true },
        { key: "nama", label: "Nama Kas", sortable: true },
        { key: "rekening.deskripsi", label: "Pemilik", sortable: true },
        { key: "rekening.nama", label: "Nama Bank", sortable: true },
        { key: "rekening.no_rekening", label: "No Rek", sortable: true },
        { key: "rekening.nama_member", label: "Member", sortable: true },
        { key: "keterangan", label: "Keterangan", sortable: true },
        { key: "saldo", label: "saldo", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      status: [
        {
          1: "Belum",
          2: "Approve SPV",
          3: "Approve Gudang",
          4: "Sudah Ambil",
        },
        {
          1: "light-danger",
          2: "light-info",
          3: "light-warning",
          4: "light-success",
        },
      ],
    };
  },
  watch: {
    "form.member_id"(value) {
      if (value) {
        this.getDataRekening();
      }
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  created() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData.id) {
      this.form.karyawan_id = userData.karyawan.id;
    }
  },
  mounted() {
  this.getDataRekening();
    this.getData();
    // this.getDataMember();
  },
  methods: {
    getNominal(items){
      return items.reduce((total, items) => total += items.saldo, 0)
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    edit(item) {
      this.form = item;
      this.id = item.id;
      this.showModal = true;
    },
    add() {
      this.resetForm();
      this.showModal = true;
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData.id) {
        this.form.karyawan_id = userData.karyawan.id;
      }
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data "${item.nama}" ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("kas/save", [item])
            .then(() => {
              this.getData();
              this.resetForm();
              this.displaySuccess({
                message: 'Kas berhasil dihapus'
              })
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        tanggal: new Date(),
        keterangan: "",
      };
    },
    submit() {
      this.$refs.formkas.validate().then((success) => {
        if (success) {
          this.label = "Loading...";
          if (this.id) {
            this.form.id = this.id;
          }
          // const payload = this.form;
      const payload = {
        tanggal: this.form.tanggal,
        nama: this.form.nama,
        karyawan_id : this.form.karyawan_id,
        // pemilik_rekening: this.form.,
        // nama_pemilik: this.form.,
        // no_rek: this.form.,
        // member_id: this.form.,
        keterangan: this.form.keterangan,
        rekening_id: this.form.rekening_id,
      }
          if (this.id) {
            payload.id = this.id ? this.id : this.form.id;
          }
          this.$store
            .dispatch("kas/save", [payload])
            .then(() => {
              this.label = "Submit";
              this.resetForm();
              this.showModal = false;
              this.getData();
            })
            .catch((e) => {
              this.label = "Submit";
              this.displayError(e);
              return false;
            });
        }
      });
    },
    getDataRekening() {
      if (this.isCabang || this.isMarketing) {
        var params = {
          member_id: this.form.member_id,
        };
      } else {
        var params = {};
      }
      this.$store
        .dispatch("rekening/getData", params)
        .then(() => {
          // this.rekening_id = this.$store.state.rekening.datas;
        let ter = JSON.parse(JSON.stringify(this.$store.state.rekening.datas));
        ter.map(item => {
          item.value = item.id
          item.text = item.nama + " - " + item.no_rekening + " - " + item.deskripsi + " - " + item.nama_member
        })
        this.rekening_id = ter
        })
        .catch((e) => console.error(e));
    },
    getDataMember() {
      // const perPage = parseInt(this.perPage);
      // const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const payload = {
        sebagai: 'cabang'
      }
      this.$store.dispatch("member/getData", payload).then(() => {
        let ter = JSON.parse(JSON.stringify(this.$store.state.member.datas));
        ter.map(item => {
          item.value = item.id
          item.text = item.nama_lengkap
        })
        this.member_id = ter
      });
    },
    getData() {
      if (this.userData.level.id != 7) {
        //SEMUA kecuali SALES
        this.$store.dispatch("kas/getData", {order:"desc"}).then(() => {
          this.items = this.$store.state.kas.datas;
          this.totalRows = this.items.length;
        });
      }
      if (this.userData.level.id == 7) {
        //SALES
        this.$store.dispatch("kas/getData", {order:"desc"}).then(() => {
          this.items = this.$store.state.kas.datas;
          this.totalRows = this.items.length;
        });
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay();
      const day = date.getDate();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6 || day === 13;
    },
    pesanBerhasilSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menyimpan data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalSimpan() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanBerhasilHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Berhasil menghapus data",
            variant: "success",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    pesanGagalHapus() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menghapus data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
  },
};
</script>
